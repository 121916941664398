<template>
  <div>
    <!--start datenschutz-component-->
    <datenschutz-component />
    <!--end datenschutz-component-->
  </div>
</template>

<script>
//import components
import DatenschutzComponent from "@/components/DatenschutzComponent.vue";

export default {
  name: "Datenschutz",

  components: { DatenschutzComponent },
};
</script>
