<template>
  <v-container>
    <v-row class="text-justify">
      <v-col cols="12" v-html="dynamicContent&&dynamicContent.dataProtection">
      </v-col>
      <v-col cols="12">
        <div v-html="$t('delivery.lang_dataProtectionContent')"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

export default {
  name: "DatenschutzComponent",
  computed:{
    ...createNamespacedHelpers("Config").mapGetters(["dynamicContent",]),
  }
};
</script>
